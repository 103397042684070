:root {
	--colors__base: #000000;
	--colors__white: #ffffff;
	--colors__dark-gray: #808080;
	--colors__text: #212122;
	--colors__primary: #cf998f;
	--colors__secondary: #ded7cb;
	--colors__border: #dbb5ae;
	--font__corm: 'Cormorant Garamond';
}
