.promo {
	background-position: center center;
	background-size: cover;
	background-repeat: no-repeat;
	&-row {
		height: 350px;
		margin-bottom: 50px;
		display: flex;
		justify-content: center;
		align-items: center; }
	&-text {
		text-align: center;
		background-color: #ffffff9d;
		padding: 20px 20px;
		width: 600px;
		border-radius: 10px;
		@media screen and (max-width: 450px) {
			padding: 50px 30px; }
		&__title {
			text-transform: uppercase;
			font-size: 2.8rem;
			line-height: 39px;
			letter-spacing: 0.1em;
			margin-bottom: 15px; }
		&__subtitle {
			letter-spacing: 0.1em;
			font-size: 1.4rem; } } }
