// Typography
//////////////////////////////////////////////////////
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond&family=Lato:wght@300;400;700&family=Pacifico&family=Roboto+Condensed&display=swap');

$font-size--base: 10px;
$font-size__coefficient--large: 2.4; //rem
$font-size__coefficient--normal: 1.4; //rem
$font-size__coefficient--small: 0.9; //rem
$font-size__coefficient--xsmall: 0.8; //rem

$font-size--large: round($font-size--base * $font-size__coefficient--large); // 24px
$font-size--normal: round($font-size--base * $font-size__coefficient--normal); // 14px
$font-size--small: round($font-size--base * $font-size__coefficient--small); // 9px
$font-size--xsmall: round($font-size--base * $font-size__coefficient--xsmall); // 8px

$line-height: 1.7;
$line-height--base: 1.428571429;
$normal-letter-spacing: normal;

$font-family__lato: 'Lato Regular', Arial, sans-serif;
$font-family__corm: 'Cormorant Garamond';
$font-family__pacifico: 'Pacifico';

$font-weight__lato--light: 300;
$font-weight__lato--regular: 400;
$font-weight__lato--bold: 700;
$font-weight__corm--regular: 400;
