.product {
	&-card {
		display: grid;
		grid-template-rows: 70% 10% 8% 12%;
		grid-template-columns: auto;
		margin-bottom: 40px; }
	&-img {
		position: relative;
		grid-column: 1 / span 2;
		grid-row: 1/2;
		width: 25rem;
		height: 35rem;
		img {
			height: 100%;
			margin: 0 auto;
			padding: 1rem; }
		&__border {
			&-one, &-two {
				border: 1px solid var(--colors__border);
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
				transition: all 300ms ease-in-out; } }
		&:hover {
			.product-img__border-one {
				opacity: 1;
				transition: all 300ms ease-in-out;
				margin-top: -7px;
				margin-left: -7px;
				width: calc(100% + 7px);
				height: calc(100% + 7px); }
			.product-img__border-two {
				opacity: 1;
				transition: all 300ms ease-in-out;
				margin-bottom: -7px;
				margin-right: -7px;
				width: calc(100% + 7px);
				height: calc(100% + 7px); }
			.product-img__quickview {
				margin-bottom: 30px;
				opacity: 1;
				transition: all 300ms ease-in-out; } }
		&__quickview {
			position: absolute;
			bottom: 0;
			left: 0;
			right: 0;
			width: 100%;
			text-align: center;
			opacity: 0;
			transition: all 300ms ease-in-out;
			span {
				height: 3.6rem;
				line-height: 3.6rem;
				text-transform: uppercase;
				color: #FFF;
				background-color: #212122;
				display: inline-block;
				padding: 0 35px;
				margin: 0 auto;
				letter-spacing: 0.2em;
				border: 1px solid transparent;
				transition: all 300ms ease-in-out;
				&:hover {
					color: #212122;
					border: 1px solid #747474;
					background-color: #FFF; } } } }
	&-name {
		grid-row: 2/3;
		place-self: center start;
		margin: 1.2rem 0 1.2rem 1.5rem;
		color: var(--colors__text);
		font-size: 2rem;
		letter-spacing: 0.05em;
		line-height: 1.2;
		@media only screen and (max-width: 768px) {
			text-align: center;
			font-size: 2.0rem; } }
	&-description {}
	&-old_price {
		grid-row: 3/4;
		grid-column: 1;
		margin: 1.2rem 0rem 1.2rem 1.5rem;
		text-decoration: line-through;
		color: #bb0606;
		font-size: 1.7rem;
		line-height: 1.2;
		@media only screen and (max-width: 768px) {
			text-align: center;
			font-size: 2rem;
			margin-bottom: 30px; } }
	&-new_price {
		grid-row: 3/4;
		grid-column: 2;
		place-self: center end;
		margin: 1.6rem 1.5rem 1.6rem 0;
		color: var(--colors__primary);
		font-size: 2rem;
		line-height: 1.2;
		@media only screen and (max-width: 768px) {
			text-align: center;
			font-size: 2rem;
			margin-bottom: 30px; } }
	&-btn {
		grid-row: 4;
		grid-column: 1 / span 2;

		width: 90%;
		margin: 0 auto; } }
