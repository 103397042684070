.cart {
	margin-top: 12rem;
	padding-bottom: 2rem;
	&-grid {
		display: grid;
		grid-template-columns: 60% 40%;
		grid-column-gap: 3rem;
		grid-row-gap: 2rem;
		align-items: center;
		font-family: var(--font__corm); }
	&-item {
		grid-column: 1;
		display: grid;
		grid-template-columns: 20% 25% 35% 10%;
		grid-template-rows: auto;
		grid-column-gap: 2rem;
		font-size: 2rem;
		&__img {
			grid-column: 1;
			grid-row: 1/ span 2; }
		&__name {
			grid-column: 2/ span 3;
			grid-row: 1; }
		&__quantity {
			&-text {
				margin-bottom: 10px; }
			&-num {
				display: inline-block;
				margin: 0 5px;
				width: 3rem;
				text-align: center;
				font-size: 2rem; } }
		&__price {
			&-text {
				margin-bottom: 1rem; }
			&-num {
				font-size: 2.6rem; } }
		&__buttons {
			grid-column: 4;
			grid-row: 1 / span 2;
			display: flex;
			flex-direction: column;
			justify-content: space-evenly;
			align-items: center;
			opacity: 0.4;
			button {
				cursor: pointer;
				outline: none;
				background-color: transparent;
				border-radius: 5px;
				border: none; }
			svg {
				font-size: 2rem; } } }
	&-checkout {
		padding: 2rem;
		text-align: center;
		border: 1px solid var(--colors__primary);
		border-radius: 10px;
		&__title {
			text-transform: uppercase;
			font-size: 2rem; }
		&__num {
			margin: 2rem 0;
			font-size: 3rem; } } }
