.advantages {
	background-color: var(--colors__primary);
	background: url(../../assets/advantages-bg.png);
	&-grid {
		display: grid;
		grid-template-columns: repeat(3, 1fr);
		margin: 30px 0;
		@media only screen and (max-width: 979px) {
			grid-template-columns: 20% 60% 20%;
			grid-template-rows: repeat(3, 1fr); }
		@media screen and (max-width: 450px) {
			grid-template-columns: 5% 90% 5%; } }
	&-item {
		min-height: 100px;
		min-width: 300px;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		margin-right: 20px;
		@media screen and (max-width: 979px) {
			&:nth-child(1) {
				grid-row: 1/2;
				grid-column: 2/3; }
			&:nth-child(2) {
				grid-row: 2/3;
				grid-column: 2/3; }
			&:nth-child(3) {
				grid-row: 3/4;
				grid-column: 2/3; } }
		&__icon {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 3rem;
			color: var(--colors__base);
			opacity: 0.7;
			width: 70px;
			height: 70px;
			border-radius: 50%;
			background: rgba(253, 253, 253, 0.7);
			@media screen and (max-width: 450px) {
				font-size: 3.5rem; } }
		&__text {
			margin-left: 30px;
			@media screen and (max-width: 450px) {
				margin-left: 15px; } }
		&__title {
			font-size: 1.7rem;
			text-transform: uppercase;
			letter-spacing: 0.15em;
			@media screen and (max-width: 979px) {
				font-size: 2rem; } }
		&__subtitle {
			font-size: 1.4rem;
			color: var(--colors__dark-gray); } } }
