.form {
	&__wrap {
		display: flex;
		flex-flow: column nowrap;
		justify-content: center; }
	&__input {
		font-family: $font-family__corm;
		font-style: italic;
		border: none;
		outline: none;
		border-bottom: 1px solid #e0e0e0;
		height: 55px;
		width: 100%;
		font-size: 1.8rem;
		color: $colors__dark-gray;
		text-align: center;
		padding: 15px;
		letter-spacing: 0.05em;
		transition: all 100ms linear;
		margin-bottom: 25px;
		&:focus {
			outline: 1px solid $colors__primary; } }
	textarea {
		resize: none;
		height: auto; } }
