.trand {
	&-slider {
		margin-bottom: 50px;
		&-item {
			display: grid;
			grid-template-rows: 70% 10% 10% 10%;
			margin-bottom: 40px;
			max-height: 480px;
			&__link {
				position: relative;
				display: block;
				overflow: hidden;
				max-height: 300px;
				min-height: 300px;
				&:hover {
					.trand-slider-item__quickview {
						margin-bottom: 30px;
						opacity: 1;
						transition: all 300ms ease-in-out; } } }
			&__img {
				margin: 0 auto;
				transition: all 0.4s;
				&:hover {
					transform: scale(1.1); } }
			&__title {
				grid-row: 2/3;
				grid-column: 1;
				max-width: 100%;
				overflow: hidden;
				color: var(--colors__text);
				margin: 20px 0 0px 15px;
				font-size: 2rem;
				letter-spacing: 0.05em;
				line-height: 1.2;
				cursor: pointer;
				@media screen and (max-width: 768px) {
					text-align: center;
					font-size: 2.0rem; } }
			&__price {
				grid-row: 3/4;
				color: var(--colors__dark-gray);
				font-size: 1.6rem;
				line-height: 22px;
				padding-left: 15px;
				padding-top: 10px;
				@media screen and (max-width: 768px) {
					text-align: center;
					font-size: 1.8rem;
					margin-bottom: 30px; } }
			&__btn {
				width: 90%;
				margin: 0 auto; }
			&__quickview {
				position: absolute;
				bottom: 0;
				left: 0;
				right: 0;
				width: 100%;
				text-align: center;
				opacity: 0;
				transition: all 300ms ease-in-out;
				span {
					height: 3.6rem;
					line-height: 3.6rem;
					text-transform: uppercase;
					color: #FFF;
					background-color: #212122;
					display: inline-block;
					padding: 0 35px;
					margin: 0 auto;
					letter-spacing: 0.2em;
					border: 1px solid transparent;
					transition: all 300ms ease-in-out;
					&:hover {
						color: #212122;
						border: 1px solid #747474;
						background-color: #FFF; } } } } } }
