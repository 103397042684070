@mixin _1170 {
    @media only screen and (max-width: 1170px) {
        @content; } }
@mixin _979 {
    @media only screen and (max-width: 979px) {
        @content; } }
@mixin _768 {
    @media only screen and (max-width: 768px) {
        @content; } }
@mixin _600 {
    @media only screen and (max-width: 600px) {
        @content; } }
@mixin _480 {
    @media only screen and (max-width: 480px) {
        @content; } }
@mixin _350 {
    @media only screen and (max-width: 350px) {
        @content; } }
