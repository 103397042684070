.hero {
	&-slider {
		margin-top: 10rem;
		padding-bottom: 2rem;
		&-item {
			width: 100%;
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			justify-content: space-between;
			@media only screen and (max-width: 900px) {
				flex-direction: column-reverse; } }
		&__text {
			width: 50%;
			display: flex;
			flex-direction: column;
			text-align: center;
			padding: 0 60px;
			@media only screen and (max-width: 900px) {
				width: 100%;
				margin-top: 2rem; } }
		&__title {
			font-size: 3.7rem;
			text-transform: uppercase;
			letter-spacing: 4px;
			margin: 10px 0 20px; }
		&__subtitle {
			font-size: 3rem; }
		img {
			min-height: 50rem;
			max-height: 50rem; } } }

.swiper {
	&-wrapper {
		padding-bottom: 5rem; } }
.swiper-button-prev, .swiper-button-next {
	width: 50px;
	height: 50px;
	border: 1px solid;
	border-color: var(--colors__border);
	border-radius: 50%;
	background-color: var(--colors__white);
	transition: all 0.3s ease-in-out;
	display: flex;
	justify-content: center;
	align-items: center;
	@media screen and (max-width: 900px) {
		display: none; }
	&::after {
		content: '';
		color: var(--colors__primary);
		border-style: solid;
		border-width: 0.10em 0.10em 0 0;
		display: inline-block;
		height: 0.35em;
		width: 0.35em;
		position: relative;
		top: 0em;
		vertical-align: top;
		left: 0.10em;
		transform: rotate(-135deg); } }
.swiper-button-next {
	&::after {
		left: -0.05em;
		transform: rotate(45deg); } }
.swiper-pagination-bullet {
	background: var(--colors__border); }
