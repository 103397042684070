
@function strip-unit($number) {
	@if type-of($number) == 'number' and not unitless($number) {
	  @return $number / ($number * 0 + 1);
	}
  
	@return $number;
}
  
  @function calcFluidFontSize($f-min, $f-max, $w-min, $w-max, $units: px) {
	$f-min: strip-unit($f-min);
	$f-max: strip-unit($f-max);
	$w-min: strip-unit($w-min);
	$w-max: strip-unit($w-max);
	
	  $k: ($f-max - $f-min)/($w-max - $w-min);
	  $b: $f-min - $k * $w-min;
  
	  $b: $b + $units;
		
	  @return calc( #{$k} * 100vw + #{$b} );

  }
  
@mixin fluidFontSize($f-min, $f-max, $w-min:480px, $w-max:1170px, $fallback: false) {
font-size: $f-min;

	@media (min-width: $w-min) {
		@if ($fallback) {
			font-size: $fallback;
		}
		font-size: calcFluidFontSize($f-min, $f-max, $w-min, $w-max, px);  
	}
	@media (min-width: $w-max) {
		font-size: $f-max;
	}
}
  

//   .fluid-font-size {
// 	@include fluidFontSize(16, 24, 480, 1280);
//   }