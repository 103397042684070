.btn {
	transition: all 0.3s;
	cursor: pointer;
	outline: none;
	color: $colors__white;
	background-color: $colors__base;
	font-family: $font-family__corm;
	text-transform: uppercase;
	font-size: 1.6rem;
	line-height: 27px;
	padding: 15px 35px;
	border-radius: 5px;
	border: none;
	&:hover {
		background-color: $colors__primary; }
	&:active {
		box-shadow: 0px 0px 7px 4px $colors__primary; }
	&--buy {
		width: 60%;
		margin: 0 auto;
		@media screen and (max-width: 450px) {
			width: 100%; } }

	&--form {
		cursor: pointer;
		min-height: 50px;
		-webkit-appearance: button;
		font-size: 1.4rem;
		font-weight: 700;
		letter-spacing: 0.2em;
		border: none;
		color: $colors__white;
		padding-left: 30px;
		padding-right: 30px;
		text-transform: uppercase;
		background: $colors__text;
		display: block;
		margin: 0 auto; }
	&--add-to-cart {
		padding: 10px 15px;
		height: 50px; }
	&--quantity {
		cursor: pointer;
		background: transparent;
		padding: 10px 20px;
		height: 54px;
		text-align: center;
		border: 1px solid $colors__primary;
		color: $colors__base;
		border-radius: 0;
		&:hover {
			color: $colors__white; } }
	&--quantity-cart {
		padding: 0 5px;
		height: 25px;
		font-size: 1rem; }
	&--delete {
		padding: 0;
		margin: 0;
		width: 25px;
		height: 25px;
		background-color: #fff;
		color: red;
		font-size: 10px;
		&:hover {
			color: #fff;
			background-color: $colors__base; } } }
