.contacts {
	&-grid {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 100px;
		margin-bottom: 50px;
		@media screen and (max-width: 768px) {
			grid-template-columns: 1fr;
			grid-row-gap: 50px; } }
	&__subtitle {
		font-size: 2.5rem;
		color: var(--colors__primary);
		margin-bottom: 15px;
		@media screen and (max-width: 768px) {
			text-align: center; }
		&.form {
			text-align: center; } }
	&__title {
		font-size: 3.5rem;
		color: var(--colors__text);
		text-transform: uppercase;
		margin-bottom: 20px;
		@media screen and (max-width: 768px) {
			text-align: center; }
		&.form {
			text-align: center; } }
	&-text {
		color: var(--colors__dark-gray);
		font-size: 1.5rem;
		@media screen and (max-width: 768px) {
			text-align: center; }
		p {
			margin: 15px 0; }
		&__socilals {
			font-size: 2.8em; } } }
