html {
	@include fluidFontSize($font-size--small, $font-size--base); }
body {
	font-family: $font-family__lato;
	font-weight: $font-weight__lato--regular;
	line-height: $line-height;
	color: $colors__text;
	overflow-x: hidden;
	background-color: $colors__white;
	background: url(../../assets/body-bg.svg);
	background-position: center center;
	background-size: cover;
	background-repeat: repeat; }
.container {
	max-width: 1165px;
	margin: 0 auto;
	padding-left: 30px;
	padding-right: 30px;
	@include _480 {
		padding-left: 15px;
		padding-right: 15px; } }
.section-header {
	padding: 5em 0 3em;
	text-align: center;
	position: relative;
	&__big {
		font-family: $font-family__lato;
		text-transform: uppercase;
		position: absolute;
		top: -30px;
		left: 0;
		right: 0;
		font-size: 10rem;
		color: rgba(35, 16, 16, 0.03);
		font-weight: $font-weight__lato--bold;
		letter-spacing: 1px;
		&--about {
			display: none; } }
	&__title {
		letter-spacing: 0.05em;
		text-transform: uppercase;
		margin-bottom: 20px;
		&--about {
			text-align: left; } }
	&__separator {
		width: 47px;
		height: 34px;
		background-image: url(../../assets/separator.png);
		display: block;
		margin: 0 auto;
		position: relative;
		&::before {
			content: "";
			display: block;
			width: 40px;
			height: 1px;
			background: #dadada;
			margin-right: 10px;
			position: absolute;
			right: 100%;
			top: 50%; }
		&::after {
			content: "";
			display: block;
			width: 40px;
			height: 1px;
			background: #dadada;
			margin-left: 10px;
			position: absolute;
			left: 100%;
			top: 50%; } } }

// Base Typographic
//////////////////////////////////////////////////////
h1 {
	font-size: 3.2rem;
	color: $colors__text;
	font-family: $font-family__corm;
	font-weight: $font-weight__corm--regular;
	line-height: $line-height;
	font-style: normal;
	text-transform: none; }
h2 {
	font-size: 2.8rem;
	color: $colors__text;
	font-family: $font-family__corm;
	font-weight: $font-weight__corm--regular;
	font-style: normal;
	text-transform: none;
	line-height: $line-height; }
h3 {
	font-size: 2.4rem;
	color: $colors__text;
	font-family: $font-family__corm;
	font-weight: $font-weight__corm--regular;
	line-height: $line-height;
	text-transform: none;
	font-style: normal; }
h4 {
	font-size: 2rem;
	color: $colors__text;
	font-family: $font-family__corm;
	font-weight: $font-weight__corm--regular;
	line-height: $line-height;
	text-transform: none;
	font-style: normal; }
h5 {
	font-size: 1.6rem;
	color: $colors__text;
	font-family: $font-family__corm;
	font-weight: $font-weight__corm--regular;
	line-height: $line-height;
	text-transform: none;
	font-style: normal; }
h6 {
	font-size: 1.4rem;
	color: $colors__text;
	font-family: $font-family__corm;
	font-weight: $font-weight__corm--regular;
	line-height: $line-height;
	text-transform: none;
	font-style: normal; }
.title {
	line-height: $line-height;
	margin-bottom: 24px; }
.text {
	font-size: 1.8rem;
	line-height: 24px;
	color: $colors__text; }
.link {
	text-decoration: underline;
	margin-top: 130px;
	font-size: 1.6rem; }
.pacifico {
	font-family: $font-family__pacifico; }
